.navigation-container {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  // justify-content: space-between;
  // align-items: center;
  background-color: white;
  position: sticky;
  top: 0px;
  padding-bottom: 30px;
  border-bottom: 1px solid #cdd1da;
  // background-color: red;
  // z-index: 999;
}

.navigation-header-sec {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  // background-color: blue;
  // height: 50px;
}

.sathibazar-icon {
  display: flex;
  // background-color: yellow;
  // width: 100px;
}

.sathibazar-icon img {
  // width: 200px;
  // height: 1;
  // background-color: yellow;
  // width: 100px;
}

.session-details {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  min-width: 250px;
  max-width: 700px;

  align-items: center;
}

.sync-data {
  height: 25px;
  width: 25px;
  cursor: pointer;
  align-self: center;
  justify-self: center;
}

.session-details span {
  font-size: medium;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif, Helvetica,
    sans-serif, Courier, monospace;
  color: #2d2f2f;
  margin-left: 15px;
}

.profile-sec {
  display: flex;
  align-items: center;
  background-color: #eafcff;
  border-radius: 6px;
  justify-content: space-evenly;
  align-items: center;

  margin-left: 15px;
  min-width: 80px;
  padding-top: 2px;
  padding-bottom: 2px;
  width: auto;
  cursor: pointer;
}

.profile-sec span {
  font-size: medium;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif, Helvetica,
    sans-serif, Courier, monospace;
  color: #2d2f2f;
  margin-left: 5px;
  margin-right: 5px;
}

.navigation-items-sec {
  display: flex;
  justify-content: space-evenly;
  // width: 100%;
  align-items: center;
  margin-left: 80px;
  margin-right: 80px;
  margin-top: 25px;
}

.nav-item-img {
  width: 32px;
  height: 32px;
  margin-top: 8px;
}

.nav-item-name {
  margin-bottom: 8px;
  color: white;
  margin-left: 5px;
  margin-right: 5px;
  font-size: smaller;
}

// new styles
.logo-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  // padding: 3px;

  // inside logo container
  .timer-date-userdropdown {
    text-align: center;
    justify-content: center;
    align-items: center;

    // below tablet view change the view to row
    //  @media (max-width: 767.9px) {
    //    flex-direction: row;
    //    gap: 5px;
    //    text-align: center;
    //  }

    // user profile dropdown
    .user-profile-icon {
      // dropdown parent class
      .dropdown {
        // background-color: red;
        // dropdown toggle
        .dropdown-toggle {
          background-color: transparent;
          border: none;
          border-radius: none;
          color: black;

          // don't display arrow
          &::after {
            display: none;
          }
        }
      }
    }
  }

  // below tablet, change the view to row
  @media (max-width: 767.9px) {
    // flex-direction: column;
  }
}

// nav links
.nav-links-container {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  padding: 2px;

  .nav-item {
    display: flex;
    flex-direction: column;
    background-color: #00c58e;
    position: relative;

    // padding: 10px;
    width: 120px;
    height: 75px;
    justify-content: space-between;
    align-items: center;
    border-radius: 10px;

    cursor: pointer;
    color: inherit;
    text-decoration: inherit;
    align-items: center;
    @media (max-width: 767.9px) {
      width: 60px !important;
      height: 85px !important;
    }
    @media (max-width: 360px) {
      width: 54px !important;
      height: 85px !important;
    }
  }

  // below tablet view give gap between links
  // @media (max-width: 767.9px) {
  //   gap: 5px;
  // }
}
