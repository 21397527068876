.modalBackground {
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.35);
  position: fixed;
  top: 0px;
  left: 0px;
  align-self: center;
  display: flex;
  justify-content: center;
  align-items: center;
  justify-self: center;
  overflow-y: scroll;
}

.modalBackground::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.modalBackground {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.modalContainer {
  /* width: 500px; */
  min-height: 200px;
  height: auto;
  border-radius: 12px;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  display: flex;
  padding: 25px;
  flex-direction: column;

  margin-top: 12vh;
  @media (max-width: 768px) {
    margin-top: 200px !important;
    margin-bottom: 10px !important;
  }
}

.modal-header-sec {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  width: 100%;
  height: 20px;
  align-items: center;
  border-bottom: 1px solid #eff2f7;
  /* margin-top: 12vh; */

  padding-bottom: 18px;
}
.modal-header-sec img {
  width: 18px;
  height: 18px;
  cursor: pointer;
}
.modal-details-sec {
  display: flex;
  /* margin-top: 20px; */
  flex-direction: column;
  /* margin-top: 12vh; */

  /* height: 500px; */
}
