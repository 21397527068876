.bill-container {
  display: flex;
  flex-direction: column;
  // margin-top: 40px;
}
.product-enter-details-container {
  display: flex;
  flex-direction: row;
  // background-color: red;
  justify-content: space-evenly;
  margin-top: 10px;

  // padding-left: 12%;
  // padding-right: 12%;
}
.product-enter-details-sec {
  display: flex;
  flex-direction: row;
  // background-color: red;
  border: 1px solid #d8cfcf;
  border-radius: 10px;
  // padding: 20px;
  // width: 30%;
  padding-top: 3px;
  padding-bottom: 10px;
  padding-left: 10px;
  // padding-right: 20px;
}
.product-subdetails-item-sec {
  display: flex;
  flex-direction: column;
}
.product-subdetails-item-sec span {
  font-size: medium;
  color: #7e8383;
}
.product-subdetails-item-sec input {
  display: flex;
  height: 30px;
  border-radius: 5px;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 3px 6px;
  border: none;
  outline: none;
  padding-left: 4px;
  color: #7e8383;
  font-size: 15px;
  margin-top: 2px;
  width: 80%;
}

.product-enter-details-sec-header {
  font-size: 17px;
  font-weight: 600;
}
.product-subdetails-sec {
  display: flex;
  flex-direction: row;
  margin-top: 4px;
}

.product-subdetails-item {
  display: flex;
  flex-direction: column;
}

.add-product-sec {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.itemcode-barcode {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  // border: 1px solid black;
  border-radius: 5px;
  margin-top: 10px;
  width: 50%;

  height: 40px;

  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 3px 6px;
  border: none;
  outline: none;
  padding-left: 8px;
  // padding: 5px;
}
.itemcode-barcode-input {
  display: flex;
  align-self: center;
  width: 50%;
  height: 2em;
  border: 0px solid white;
  outline: none;
  // margin-left: 8px;
  // text-align: center;
  font-size: 15px;
  margin-left: 5px;
}
.barcode-img {
  width: 70px;
  height: 35px;
  cursor: pointer;
  margin-right: 5px;
}
.added-products-details-sec {
  display: flex;
  flex-direction: column;
  // height: 500px;
}

.add-new-product-sec {
  display: flex;
  flex-direction: row;
  margin-top: 10px;

  height: 40px;
  width: 12%;

  border-radius: 8px;

  background-color: #00c58e;
  // padding: 10px;

  border: none;

  cursor: pointer;
  color: inherit;
  text-decoration: inherit;
  color: white;
  justify-content: center;
  align-items: center;
}
.add-new-product-sec span {
  color: white;
  margin-left: 10px;
  margin-right: 10px;
  font-size: smaller;
  // width: 80%;

  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}
.add-new-product-sec img {
  margin-left: 10px;
}

input[type="datetime-local"]::-webkit-inner-spin-button,
input[type="datetime-local"]::-webkit-calendar-picker-indicator {
  display: none;
  -webkit-appearance: none;
}

// table styles -------------------------------------------->>>>>
.product-table-container {
  display: flex;
  flex-direction: column;
  // background-color: red;
  margin-top: 18px;
}

.table-column-header {
  display: flex;
  flex-direction: row;
  // flex: ;
  // background-color: red;

  // align-items: center;
  justify-content: space-between;
  height: 50px;

  // border-left: 1px solid;
  // border-right: 1px solid;
  // align-items: center;
}

.table-item {
  display: flex;
  // flex: 1;
  width: 20%;
  align-items: center;

  background-color: #eff2f7;
  justify-content: center;
  font-weight: 600;
  // border-right: 1px;
}
.table-row-details {
  display: flex;
  flex-direction: column;
  // flex: ;
  // align-items: center;
  justify-content: space-between;

  // height: 35px;
  // border-left: 1px solid;
  // border-right: 1px solid;

  // align-items: center;
}

//no-product
.no-products-section {
  display: flex;
  align-self: center;
  height: 40vh;
  // justifySelf: 'center',
  // background-color: red;
  width: 100%;
  align-items: center;
  justify-content: center;
  font-size: larger;
}

.product-list-container {
  display: flex;

  flex-direction: column;
  height: auto;
  overflow-y: scroll;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  max-height: 90px;
  // margin-bottom: 5px;
  // border: 1px solid black;
}
.product-list-container::-webkit-scrollbar {
  display: none;
}

.product-row-detail {
  display: flex;
  flex-direction: row;
  // border-left: 1px solid;
  // border-right: 1px solid;
  border-bottom: 1px solid #eff2f7;
}
.table-row-item {
  display: flex;
  // flex: 1;
  width: 20%;
  align-items: center;

  background-color: white;
  justify-content: center;
  height: auto;

  // border-bottom: 1px solid;
}
.table-row-item div {
  display: flex;
  // background-color: red;
  border: 0.05rem solid black;
  height: 15px;
  width: 15px;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  text-align: center;
  user-select: none;
  cursor: pointer;

  // border-bottom: 1px solid;
}

.table-row-item-input {
  // margin-left: 10px;
  width: 20%;
  text-align: center;

  height: 35px;
  border-radius: 5px;
  background-color: white;
  // box-shadow: rgba(0, 0, 0, 0.35) 0px 3px 6px;
  border: none;
  outline: none;
  padding-left: 5px;
  color: #7e8383;
  font-size: 15px;

  // border-left: 1px solid #eff2f7;
  // border-right: 1px solid #eff2f7;
  // border-left: 1px solid grey;
  // border-right: 1px solid grey;

  // border-left: ;
}

//total

.total-section {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  border-bottom: 1px solid #eff2f7;
  padding-top: 10px;
  padding-bottom: 10px;
  // background-color: red;

  // border-left: 0.1em solid #707070;
}

.total-row-details {
  display: flex;
  flex-direction: column;
  // border-left: 1px solid #eff2f7;

  // border-bottom: 1px solid #eff2f7;

  width: 60%;
}

.total-row-item {
  display: flex;
  // border-bottom: 1px solid #eff2f7;

  padding-left: 40px;
}

.total-amount {
  display: flex;
  // border: 1px solid;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 17%;
}

//add product modal
.search-products-input {
  height: 35px;
  border-radius: 5px;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 3px 6px;
  border: none;
  outline: none;
  padding-left: 8px;
  color: #7e8383;
  font-size: 15px;
}
.addproduct-container {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
}

.added-product-details {
  display: flex;
  // flex: 1;
  width: 20%;
  align-items: center;

  background-color: white;
  justify-content: center;
}

.addedproduct-item {
  display: flex;
  // flex: 1;
  width: 20%;
  align-items: center;

  background-color: white;
  justify-content: center;
}

.added-product-quantity {
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.added-product-quantity span {
  align-self: center;
  // font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}
.added-product-quantity div {
  margin-left: 20px;

  display: flex;
  // background-color: red;
  border: 0.05rem solid black;
  height: 15px;
  width: 15px;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  text-align: center;
  user-select: none;
  cursor: pointer;
  // font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.added-product-quantity input {
  margin-left: 10px;
  width: 30%;
  height: 35px;
  border-radius: 5px;
  background-color: white;
  border: none;
  outline: none;
  padding-left: 8px;
  font-size: 15px;
  border-left: 1px solid grey;
  border-right: 1px solid grey;
  text-align: center;
  @media (max-width: 768px) {
    width: 30% !important;
  }
}
.added-product-variant {
  display: flex;
  margin-top: 10px;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}
.added-product-variant select {
  margin-right: 25px;
  border: none;
  background-color: #eff2f7;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 8px;
  height: 35px;
}
.added-product-variant option {
  border: none;
  background-color: white;
}
.add-to-bill-button {
  width: 20%;
  align-self: flex-end;
  justify-self: flex-end;

  height: 40px;
  background-color: #171f68;
  border: none;
  border-radius: 8px;
  width: 100px;
  margin-left: 22px;
  margin-top: 25px;
  color: white;
  cursor: pointer;
}

//payment and save section ---------------------->
.payment-draft-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  // background-color: red;
  justify-content: space-between;
}
.refund-comment-input {
  display: flex;
  height: 35px;
  border-radius: 5px;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 3px 6px;
  border: none;
  outline: none;
  padding-left: 8px;
  color: #7e8383;
  font-size: 15px;
  // margin-top: 15px;
  width: 40%;
}
.payment-draft-sec {
  display: flex;
  margin-top: 10px;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.payment-draft-sec select {
  margin-right: 25px;
  border: none;
  background-color: #eff2f7;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 8px;
  height: 35px;
}
.payment-draft-sec option {
  border: none;
  background-color: white;
}
.payment-draft-sec button {
  height: 40px;
  background-color: #171f68;
  border: none;
  border-radius: 8px;
  width: 100px;
  margin-left: 22px;
  color: white;
  cursor: pointer;
  align-self: center;
}

//refund section

.refund-product-table-container {
  display: flex;

  flex-direction: column;
  height: 25vh;
  // overflow-y: scroll;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  // margin-bottom: 5px;
  // border: 1px solid black;
}
.refund-product-table-container::-webkit-scrollbar {
  display: none;
}
.refund-table-row-details {
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  // align-items: center;
  justify-content: space-between;

  // height: 35px;
  // border-left: 1px solid;
  // border-right: 1px solid;

  // align-items: center;
}
.refund-table-row-details::-webkit-scrollbar {
  display: none;
}
