.accounts-container {
	display: flex;

	flex-direction: column;
}

.card-details-section {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
}

.card-container {
	display: flex;
	width: 30%;
	padding-top: 4vh;
	padding-bottom: 4vh;
	align-items: center;

	// padding-left: 20px;
	// padding-right: 20px;
	justify-content: space-around;
	// align-items: center;
	flex-direction: row;
	border-radius: 10px;
}

.card-header-value {
	display: flex;
	flex-direction: column;
}

.card-header-value {
	display: flex;
	flex-direction: column;
	color: white;
	margin-left: 20px;
}

.card-header {
	font-size: 18px;
	font-weight: 500;
}

.card-value {
	font-size: 18px;
}

.card-container img {
	margin-right: 20px;
	// height: 50px;
	// width: 50px;
}

.graph-section {
	display: flex;
	flex-direction: column;
}

.graph-section1 {
	display: flex;
	justify-content: space-between;
	flex-direction: row;
	// z-index: -1;
}

.graph-section1 div {
	display: flex;
	width: 45%;
	// background-color: red;
	align-items: center;
	justify-content: center;

	// height: 1000;
	// margin-right: 100px;
}

.graph-section2 {
	// background-color: red;
	// display: flex;
	width: 100%;
	// z-index: -1;
}


// new styles
.card-container-test {
	// background-color: red;
	// width: 400px;
	color: white;
	// margin-left: 0px !important;
	// margin-right: 0px !important;
	// below tablet i.e,767.9px
	@media (max-width:767.9px) {
		flex-direction: column;
		gap: 6px;
	}
}