.bill-history-search-and-filter {
  display: flex;
  // width: 100%;
  flex-direction: row;
  justify-content: space-between;
}
.bill-history-search {
  display: flex;
  // flex: 8%;
  width: 100%;
}
.bill-history-search input {
  display: flex;
  // flex: 1;
  width: 80%;

  height: 35px;

  border-radius: 5px;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 3px 6px;
  border: none;
  outline: none;
  padding-left: 8px;
  color: #7e8383;
  font-size: 15px;
}
.bill-history-filter {
  display: flex;

  align-items: center;
}

.bill-history-filter select {
  // margin-right: 25px;
  border: none;
  background-color: #eff2f7;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 8px;
  height: 35px;
}
.bill-history-filter option {
  border: none;
  background-color: white;
}
.switch-field {
  display: flex;
  margin-bottom: 36px;
  overflow: hidden;
  margin: 15px auto;
}

.switch-field input {
  position: absolute !important;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  width: 1px;
  border: 0;
  overflow: hidden;
}

.switch-field label {
  background-color: #00c58e;
  color: rgb(255, 255, 255);
  font-size: 14px;
  line-height: 1;
  text-align: center;
  padding: 8px 16px;
  margin-right: -1px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.3), 0 1px rgba(255, 255, 255, 0.1);
  transition: all 0.1s ease-in-out;
}

.switch-field label:hover {
  cursor: pointer;
}

.switch-field input:checked + label {
  background-color: #06215f;
  box-shadow: none;
}

.switch-field label:first-of-type {
  border-radius: 4px 0 0 4px;
}

.switch-field label:last-of-type {
  border-radius: 0 4px 4px 0;
}

.sathi-green {
  cursor: pointer;
  color: initial;
  transition: color 0.3s ease-in-out;
}
.text-align-center {
  text-align: center;
}

.sathi-green:hover {
  color: #01c995;
}
.underline {
  text-decoration: underline;
}

.custom-header-css {
  thead {
    tr {
      th {
        div:last-child {
          min-height: 38px;
        }
      }
    }
    td:nth-last-child(2) {
      width: 150px !important;
    }
  }
}
