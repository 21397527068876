.modal-popup-container {
    // background-color: orange;
    overflow: auto;

    /* modal content */
    .modal-content {
        // background-color: red;
        font-size: 15px;
        width: 650px;

        // below laptop screen change the content width
        @media (max-width:1023.9px) {
            width: 550px;
        }

        // below tablet screen change the content width
        @media (max-width:767.9px) {
            width: 410px;
        }

        // in below mobileL i,e., 424.9px
        @media (max-width:424.9px) {
            width: 350px;
        }

         // in below mobileM i,e., 374.9px
         @media (max-width:374.9px) {
            width: 310px;
        }
    }
}