.layout-container {
	display: flex;
	flex-direction: column;
	margin-left: 30px;
	margin-right: 30px;
	margin-bottom: 50px;

	// margin-top: 20px;
}

.logo-userinfo-navlinks-container
{
	// background-color: orange;
	display: flex;
	flex-direction: column;
	// gap: 5px;
}