.searchInputs {
  /* margin-top: 105px; */
  display: flex;
}

.search input {
  /* background-color: white;
	border: 0;
	border-radius: 2px;
	border-top-right-radius: 0px;
	border-bottom-right-radius: 0px;
	font-size: 18px;
	padding: 15px;
	height: 30px;
	width: 300px; */

  height: 35px;
  width: 100%;
  border-radius: 5px;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 3px 6px;
  border: none;
  outline: none;
  padding-left: 8px;
  color: #7e8383;
  font-size: 15px;
}

.searchIcon {
  height: 60px;
  width: 50px;
  background-color: white;
  display: grid;
  place-items: center;
}

input:focus {
  outline: none;
}
.searchIcon svg {
  font-size: 35px;
}

.dataResult {
  margin-top: 8px;
  width: 50%;
  height: 200px;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  overflow: hidden;
  overflow-y: auto;

  z-index: 99;
  position: absolute;
  padding-top: 8px;
}

.dataResult::-webkit-scrollbar {
  display: none;
}

.dataResult .dataItem {
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  color: black;
  cursor: pointer;
  /* margin-top: 10px; */
  @media (max-width: 768px) {
    height: auto !important;
  }
}

.dataItem p {
  margin-left: 10px;
  font-size: medium;
}
a {
  text-decoration: none;
}

a:hover {
  background-color: lightgrey;
}

#clearBtn {
  cursor: pointer;
}
