// global filter
.global-filter{
    // background-color: red;
    display: flex;
    
    justify-content: center;
    align-items: center;
    padding: 1% 0 1% 0;
    // input group
    .input-group{
        // hide the span
        span{
            display: none;
        }

        &:focus{
            box-shadow: none !important;
        }
    }

    // close icon
    .close-icon{
        font-size: 50px;
        transform: translate(0px,-7px) ;
        cursor: pointer;
        
    }
}
.deleted-row{
    background-color: rgb(214, 207, 207) !important;
}
tbody tr.deleted-row:hover{
    background-color: rgb(214, 207, 207) !important ; 
}