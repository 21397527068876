.login-container {
	height: 100vh;
	width: 100%;
	// background-color: red;
	// padding: 5px;
	// margin-top: 5%;

	.form-image-container {
		// width: 100%;
		height: 100%;

		// store image view
		.image-view {
			// background-color: orange;
			height: 100%;

			img {
				width: 100%;
				height: 100%;
			}

			// below 767.9px
			@media (max-width:767.9px) {
				height: 270px;
			}
		}

		// form view
		.form-view {
			// background-color: blue;
			display: flex;
			flex-direction: column;

			// sync icon
			.sync {
				div {
					display: flex;
					justify-content: flex-end;
					align-items: flex-end;
					padding-top: 2px;
					padding-bottom: 2px;

					// icon image
					.sync-data {
						margin-right: 20px;
					}
				}
			}

			// form container
			.form-container {
				flex: 1;
				justify-content: center;
				align-items: center;

				// first child
				.wrapper {
					height: 100%;
					display: flex;
					justify-content: center;
					align-items: center;

					// container
					.container {

						// form title
						.form-title {
							// background-color: aqua;
							padding: 2px;
							text-transform: capitalize;
							font-weight: bold;
							text-align: center;
							font-size: 25px;
						}

						// form fields
						.fields-container {
							// background-color: aquamarine; 	
							padding: 3px;

							// employee ID Field and password
							.field {
								// background-color: brown;
								padding: 2px;
								width: 100%;

								div {
									display: flex;
									flex-direction: column;
									gap: 2px;
								}
							}

							// clear and login buttons
							.clear-login-buttons {
								// background-color: darksalmon;
								display: flex;
								padding: 2px;
								justify-content: space-around;
								align-items: center;
								flex-direction: row;
								margin-left: 0px !important;
								margin-right: 0px;
								// width: 100%;

								.btn {
									width: max-content;
									font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif !important;
								}
							}
						}
					}
				}
			}
		}

		// below 767.9px
		@media (max-width:767.9px) {
			height: 0px;
		}
	}
}

// fields title
.field-title {
	font-size: 16px;
	font-weight: bold;
	text-transform: capitalize;
}

.login-banner {
	display: flex;
	flex: 1;
	height: 100vh;
	// width: 100%;
}

.enter-login-details-header-sec {
	display: flex;
	// flex: 1;
	width: 50%;
	height: 100vh;
	flex-direction: column;
	// justify-content: space-around;
}

.login-text {
	flex: 2;

	position: relative;
	padding-left: 5%;
	color: #707070;
	font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
		'Lucida Sans', Arial, sans-serif;

	// align-items: flex-end;
}

.enter-login-details-sec {
	flex: 3.5;

	padding-left: 5%;
	// height: 100%;
	align-items: center;
	justify-content: space-between;
	margin-top: 30px;
}

.input-field-section {
	display: flex;
	flex-direction: column;
}

.input-header {
	display: flex;
	color: #707070;
	font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
}

.input-field {
	display: flex;
	height: 35px;
	// width: 65%;

	border-radius: 5px;
	background-color: white;
	box-shadow: rgba(0, 0, 0, 0.35) 0px 3px 6px;
	border: none;
	outline: none;
	padding-left: 8px;
}

.submit-sec {
	display: flex;
	// justify-content: center;
	margin-top: 30px;
}

.login-reset-clear {
	border: 1px solid #cd0a0a;
	border-radius: 8px;
	font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
	height: 40px;
	width: 120px;
	background-color: white;
	color: #cd0a0a;
	cursor: pointer;
}

.login-submit {
	border: 1px solid #088bfe;
	border-radius: 8px;

	font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
	height: 40px;
	width: 120px;
	background-color: white;
	// color: #088bfe;
	// cursor: pointer;
	margin-left: 30px;
}
.invalid-user-text{
	color: #cd0a0a;	
}
.form-login{
	display: flex;
	flex-direction: column;
	gap: 5px; 
	padding: 3px;
}


///@at-root