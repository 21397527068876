.table-row-item {
  font-size: 12px;
}
.custom-text-field {
  margin-top: 40px;
  width: 100px;
}
.custom-dropdown {
  margin-top: 40px;
  width: 80%;
}

.custom-btn {
  margin-top: 40px;
}

.barCodeAlign {
  flex-direction: row;
  @media (max-width: 768px) {
    flex-direction: column !important;
  }
}

.custom-dropdown {
  svg {
    width: 2em !important;
  }
}
